<template>
  <!-- FIX IMG IN PRODUCT -->
  <div class="mt-5 pt-3 mb-5">
    <h2>{{ $t("bestsellers.name") }}</h2>
    <img :src="images.wasabi_icon" alt="wasabi" width="280" />
    <h4 v-html="$t('bestsellers.wasabi.des')"></h4>
    <Product :des="$t('bestsellers.wasabi.s')" :url="images.wasabi_s" />
    <Product :des="$t('bestsellers.wasabi.l')" :url="images.wasabi_l" />
    <img :src="images.mustard_icon" alt="mustard" width="280" />
    <h4 v-html="$t('bestsellers.mustard.des')"></h4>
    <Product :des="$t('bestsellers.mustard.yellow')" :url="images.mustard_yl" />
    <Product :des="$t('bestsellers.mustard.whole')" :url="images.mustard_wh" />
  </div>
</template>

<script>
import Product from "@/components/bestsellers/Product.vue";
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  components: { Product },
  data() {
    return {
      images: {
        wasabi_icon: "",
        mustard_icon: "",
        wasabi_s: "",
        wasabi_l: "",
        mustard_yl: "",
        mustard_wh: "",
      },
    };
  },
  created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `bestsellers/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>

<style scoped>
img {
  padding: 4%;
}
.order {
  padding: 3%;
}
h4 {
  color: #637a88;
  padding: 0% 5% 0% 5%;
}
@media screen and (max-width: 700px) {
  img {
    width: 40%;
  }
}
</style>
