<template>
  <div class="container">
    <img :src="url" />
    <div class="text">
      <h4 v-html="des"></h4>
    </div>
  </div>
</template>

<script>
export default {
  props: ["url", "des"],
};
</script>

<style scoped>
img {
  width: 30%;
}
.text {
  padding: 5%;
  margin-bottom: 2%;
}
.container {
  width: 80%;
  padding: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
}
h4 {
  font-family: Cloud;
}
::v-deep b {
  font-family: Cloud Bold;
}
@media screen and (min-width: 780px) {
  img {
    position: relative;
    float: left;
    width: 20%;
  }
  .text {
    display: inline-block;
    text-align: left;
    width: 70%;
  }
}
</style>
